if (navigator.geolocation) {
	(function ($) {
		var geolocation = {
			lat: '',
			lng: '',
			state: '',
			city: '',
	
			init: function () {
				geolocation.getLocation();
			},
	
			getLocation: function () {
				navigator.geolocation.getCurrentPosition(
					geolocation.locationSuccess,
					geolocation.locationFailure
				);
			},
	
			locationSuccess: function (position) {
				geolocation.lat = position.coords.latitude;
				geolocation.lng = position.coords.longitude;
				geolocation.translateLocationFromCoordinates();
			},
	
			locationFailure: function (failure) {
				var $input = $('input[name="location"]');

				if ($input.val() === '' && $input.data('ip-location')) {
					$input.val($input.data('ip-location'));
				}
			},
	
			translateLocationFromCoordinates: function () {
				$.get('/api/client-geolocation', {
					'lat': geolocation.lat,
					'lon': geolocation.lng
				}).done(function (resultData) {
					if (resultData) {
						geolocation.city = resultData.attributes.city;
						geolocation.state = resultData.attributes.state;
						geolocation.populateInput();
					} else {
						geolocation.locationFailure();
					}
				});
			},
	
			populateInput: function () {
				var $input = $('input[name="location"]');

				if ($input.val() === '') {
					$input.val(geolocation.city + ', ' + geolocation.state);
				}
			}
		}

		$(document).on('focus.geolocation', 'input[name="location"]', geolocation.init);

	}(jQuery));
}
