(function ($) {
    function curtainActivate($this, isActive) {
        var $head = $('.curtain__head[aria-controls="' + $this.attr('id') + '"]');
        
        $head.toggleClass('is-active', isActive);

        $this
            .removeClass('is-hidden')
            .trigger('curtain-changing')
            .transitionCSS(function () {
                $this.toggleClass('is-hidden', ! isActive).trigger('curtain-changed');
                $(document).trigger('document-height-changed');
            })
            .filter('[aria-expanded]')
            .attr('aria-expanded', isActive)
            .filter('[aria-expanded="true"]')
            .focus()
            .find('[data-curtain-focus]')
            .first()
            .focus();
    }

    function curtainShowHandler(event) {
        event.stopPropagation();
        curtainActivate($(this), true);
    }

    function curtainHideHandler(event) {
        event.stopPropagation();
        curtainActivate($(this), false);
    }

    function curtainActivateHandler(event) {
        event.stopPropagation();
        curtainActivate($(this), $(this).hasClass('is-hidden'));
    }

    function curtainHeadActivateHandler(event) {
        var $this = $(this),
            $body = $('#' + $this.attr('aria-controls')),
            $close = $('#' + $this.data('curtain-close') + ':not(.is-hidden)'),
            $toggle = $($this.data('curtain-toggle')).not('.is-hidden');

        event.stopPropagation();

        if ($body.hasClass('is-hidden') && ($close.length || $toggle.length)) {
            $close
                .one('curtain-changed', function (event) {
                    event.stopPropagation();
                    $body.trigger('curtain-activate');
                })
                .trigger('curtain-activate');
            $toggle
                .one('curtain-changing', function (event) {
                    event.stopPropagation();
                    $body.trigger('curtain-activate');
                })
                .trigger('curtain-activate');
        }
        else {
            $body.trigger('curtain-activate');
        }
    }

    function curtainHashActivateHandler(event) {
        $(function () {
            if (window.location.hash) {
                var $activate = $(window.location.hash);
                
                if ($activate.hasClass('curtain__head')) {
                    $activate.trigger('curtain-activate');
                } else {
                    curtainActivate($(window.location.hash), true);
                }
            }
        });
    }

    function curtainHeadClickHandler(event) {
        event.preventDefault();
        $(this).trigger('curtain-activate');
    }



    $(document)
        .on('curtain-activate', '.curtain__head', curtainHeadActivateHandler)
        .on('click.curtain', '.curtain__head', curtainHeadClickHandler)
        .on('curtain-activate', '.curtain, [class^="curtain@"], [class*=" curtain@"]', curtainActivateHandler)
        .on('curtain-show', '.curtain, [class^="curtain@"], [class*=" curtain@"]', curtainShowHandler)
        .on('curtain-hide', '.curtain, [class^="curtain@"], [class*=" curtain@"]', curtainHideHandler)
        .on('ready.curtain', curtainHashActivateHandler);

    $(window).on('hashchange.curtain', curtainHashActivateHandler);

}(jQuery));
