(function ($) {
    
    function configuratorOptionOnChange(event) {
        var $option = $(this),
            $options = $('.configurator__option[name="' + $option.attr('name') + '"]'),
            $optionItems = $('.configurator__option-item'),
            $detailsFormat = $('[data-configurator-details-format]');
        
        $options.each(configuratorOptionToggleImage);
    }
    
    function configuratorOptionEmptyCheckStandard() {
        var $option = $(this),
            $options = $('.configurator__option[name="' + $option.attr('name') + '"]');
            
        if ($options.filter(':checked').length === 0) {
            $options.filter('[value=""]').prop('checked', true);
        }
    }
    
    function configuratorOptionToggleImages() {
        var $option = $(this),
            $options = $('.configurator__option[name="' + $option.attr('name') + '"]');
        
        $options.each(configuratorOptionToggleImage);
    }
    
    function configuratorOptionToggleImage() {
        var $option = $(this);
        
        $('.configurator__image[data-configurator-option="' + $option.val() + '"]')
            .toggleClass('is-active', $option.is(':checked'));
    }
    
    
    
    $(document).on('change.configurator', '.configurator__option', configuratorOptionOnChange);
}(jQuery));
