(function ($) {
    
    function drawerButtonOpenClickHandler(event) {
        $(this).closest('[data-drawer]').find('[data-drawer__item]').addClass('is-active');
        $(document.body).addClass("u-no-scroll");
    }
    
    function drawerButtonCloseClickHandler(event) {
        $(this).closest('[data-drawer]').find('[data-drawer__item]').removeClass('is-active');
        $(document.body).removeClass("u-no-scroll");
    }
    
    $(document)
        .on('click.drawer', '[data-drawer__button--open]', drawerButtonOpenClickHandler)
        .on('click.drawer', '[data-drawer__button--close]', drawerButtonCloseClickHandler);
}(jQuery));
